

!function(t,e){"object"==typeof exports&&"undefined"!=typeof module?e():"function"==typeof define&&define.amd?define(e):e()}(0,function(){"use strict";var t={};!function(e){function r(){var r=e(this),i=r.attr("src");if(!t[i]){var n=e.Deferred();e.get(i,function(t){n.resolve(e(t).find("svg"))}),t[i]=n.promise()}t[i].then(function(t){var i=e(t).clone();r.attr("id")&&i.attr("id",r.attr("id")),r.attr("class")&&i.attr("class",r.attr("class")),r.attr("style")&&i.attr("style",r.attr("style")),r.attr("width")&&(i.attr("width",r.attr("width")),r.attr("height")||i.removeAttr("height")),r.attr("height")&&(i.attr("height",r.attr("height")),r.attr("width")||i.removeAttr("width")),i.insertAfter(r),r.trigger("svgInlined",i[0]),r.remove()})}e.fn.inlineSvg=function(){return this.each(r),this}}(jQuery)});




function gridwidth(){
  var gridW = $('.icon-row-col-top .grid-icon-list li').outerWidth();
  var cantgrid = $('.featureweb-grid-main').width();
  var gridactionW = gridW + gridW ;
  var activewide = cantgrid - gridactionW;
  $('.featureweb-row-colume').css('min-width',gridW );
  $('.grid-icon-active-box').css('min-width',activewide );
  

}



(function(jQuery) {
    $(document).ready(function() {
      $('.svg').inlineSvg();
      gridwidth();

      $(".toggle-btn").click(function() {
        $('.menu-area').slideToggle();
     });

      $(".grid-icon-list li").click(function() {
        $(".grid-icon-list li").removeClass("active");
        // $(".tab").addClass("active"); // instead of this do the below 
        $(this).addClass("active");
     });

     $(".grid-icon-list li").hover(function() {
      $(".grid-icon-list li").removeClass("active");
      // $(".tab").addClass("active"); // instead of this do the below 
      $(this).addClass("active");
   });

   var headerHeight = $('header.theme-header').outerHeight(); // Target your header navigation here
	
   $('.scrolling-link a').click(function(e) {
     
     var targetHref   = $(this).attr('href');
     
   $('html, body').animate({
     scrollTop: $(targetHref).offset().top - headerHeight // Add it to the calculation here
   }, 400);
     e.preventDefault();
   });

   //$('.sub-accordion ul > li:has(ul)').parent().addClass('no-sub-item');




     //$( ".sub-accordion ul li" ).has( "ul" ).parent().addClass('fttttttt');

     $(".sub-accordion > ul>li").each(function(){
      if($(this).has("ul").length != 1){
          $(this).parent().addClass('no-sub-item');
       };
      });


  });


   $(window).on('load', function () {
  });


  $(window).on('resize', function() {
    setTimeout(function() { 
      gridwidth();
  }, 500);

});

$(window).bind("load resize", function() {
  setTimeout(function() { 
    gridwidth();
}, 500);


});


  $(window).scroll(function(){
    if ($(window).scrollTop() >= 40) {
      $('.theme-header').addClass('fixed-is-active');
     }
     else {
      $('.theme-header').removeClass('fixed-is-active');
     }
  });

})(jQuery);
